import React from 'react';
import Layout from '../components/Layout';
import AssetsTemplate from '../templates/AssetsTemplate';
import { graphql } from 'gatsby';

const AssetsPage = ({ data }) => {
	const assets = data.assets.edges.map((edge) => ({
		name: edge.node.name,
		trustee: edge.node.trustee,
		industry: edge.node.industry,
		slug: edge.node.fields.slug,
		desc: edge.node.desc,
	}));
	return (
		<Layout>
			<AssetsTemplate assets={assets} />
		</Layout>
	);
};

export default AssetsPage;

export const query = graphql`
	query {
		assets: allAssetsJson(sort: { fields: [date], order: DESC }) {
			edges {
				node {
					name
					trustee
					industry {
						name
					}
					desc
					fields {
						slug
					}
				}
			}
		}
	}
`;
