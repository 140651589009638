import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import Masthead from '../components/Masthead';
import CtaBar from '../components/CtaBar';

//import Container from 'react-bulma-components/lib/components/container';
import { PageWrap } from '../styles/common';

export default ({ assets }) => {
	return (
		<PageWrap>
			<Helmet>
				<title>Assets | Spergel Corporate</title>
				<style>{`div#chat-widget-container {display: none;}`}</style>
			</Helmet>
			<Masthead
				image="/img/Spergel_AssetsForSale_MH_Desktop.jpg"
				title="Assets for Sale"
			/>
			<Wrap>
				<div className="container">
					{assets.map((asset, i) => (
						<React.Fragment key={i}>
							<h3>
								<a href={asset.slug}>{asset.name}</a>
							</h3>
							<h4>{asset.trustee}</h4>
							<h4>{asset.industry.map((e) => e.name).join(', ')}</h4>
							<p>{asset.desc}</p>
						</React.Fragment>
					))}
				</div>
			</Wrap>
			<CtaBar>
				<h3>
					Let's start a conversation.
					<br />
					<a href="/contact">Contact us today.</a>
				</h3>
			</CtaBar>
		</PageWrap>
	);
};

const Wrap = styled.div`
	padding: 4rem 2rem;
	min-height: calc(100vh - 47rem);
	h2 {
		font-weight: 300;
		font-size: 2.5rem;
		margin: 0 0 2rem;
	}
	h3 {
		margin: 0.5rem 0;
		line-height: 1;
		font-size: 1.5rem;
	}
	h4 {
		margin: 0;
		font-weight: 400;
		line-height: 1.2;
	}
	a {
		color: inherit;
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}
`;
